import { RefObject, useEffect } from 'react';

interface OutsideClickHandlerProps {
  ref: RefObject<HTMLElement>;
  handler: () => void;
}

const useOutsideClick = ({ ref, handler }: OutsideClickHandlerProps) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      const target = event.target instanceof Node ? event.target : null;
      if (ref.current && !ref.current.contains(target)) {
        handler();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, handler]);
};

export default useOutsideClick;
