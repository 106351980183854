import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './FeaturedCarousel.sass';

export interface FeaturedCarouselSlides {
  image: string;
  title: string;
  subtitle: string;
}
export interface FeaturedCarouselProps {
  data: FeaturedCarouselSlides[];
}

const FeaturedCarousel = ({ data }: FeaturedCarouselProps) => {
  const BoldTextConverter = ({ text }) => {
    return (
      <h2
        className="featured-carousel__item__title"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    );
  };
  return (
    <Slider
      className="featured-carousel cell small-10"
      autoplay
      dots
      slidesToShow={1}
      infinite
      autoplaySpeed={10000}
      arrows={false}
      dotsClass="slick-dots"
    >
      {data.map((slide, index) => (
        <div
          key={index}
          className="featured-carousel__item flex-container align-center"
        >
          <BoldTextConverter text={slide.title} />
          <img
            className="featured-carousel__item__img"
            src={slide.image}
            alt={slide.title}
          />
          <p className="featured-carousel__item__subtitle">{slide.subtitle}</p>
        </div>
      ))}
    </Slider>
  );
};

export default FeaturedCarousel;
