class Sofatutor.Classes.TrialFlow
  context: null

  constructor: ->
    @cache()
    @bindEvents()

  cache: ->
    @$freeTrialPopup              = $('.js-trial-flow-popup')
    @$videoTab                    = $('.js-video-conversion-button')
    @$exercisesTab                = $('.js-exercise-conversion-button')
    @$exercisesButton             = $('.js-videos-test-button')
    @$workSheetTab                = $('.js-worksheet-conversion-button')
    @$chatTab                     = $('.js-chat-conversion-button')
    @$lockAccordionButton         = $('.js-lock-accordion-button')
    @$backToVideoButton           = $('.js-videos-back-to-video-button')
    @$videojsPlayer               = videojs?.getPlayers?()['player-0'] || videojs?.players?['player-0']
    @$nativePlayer                = $('#player-0')[0]
    @$exercisesApp                = $('.exercises-app')
    @deviceSize                   = new Sofatutor.Classes.DeviceSizeHelper
    @$videoPlaceholder            = $('.videos-player-placeholder')
    @$videoPlayerContainer        = $('.videos-player-container')
    @$trialPopupOverlay           = $('.trial-flow__overlay')
    @$closePopupIcon              = $('.js-trial-flow-popup .icon--close')

  bindEvents: ->
    @$videoTab.on                    'click', @selectVideoTab
    @$exercisesTab.on                'click', @selectExerciseTab
    @$exercisesButton.on             'click', @selectExerciseTab
    @$workSheetTab.on                'click', @selectWorksheetTab
    @$chatTab.on                     'click', @selectChatTab
    @$lockAccordionButton.on         'click', @selectLockAccordionButton
    @$backToVideoButton.on           'click', @selectVideoTab
    @$videojsPlayer.one              'play',  @selectVideoTab if Sofatutor.player.videoJsLoaded()
    @$nativePlayer.addEventListener  'play',  @selectVideoTab, once: true if Sofatutor.player.isNative
    @$videoPlaceholder.on            'click', @selectVideoTab

    @$trialPopupOverlay.on           'click', @closePopup if Sofatutor.browser.isMobile
    @$closePopupIcon.on              'click', @closePopup

  assignCurrentTab: (elem) ->
    $selectedTab = elem
    $('.is-current').removeClass 'is-current'
    $selectedTab.addClass 'is-current'
    @scrollToVideoPlayer()

  selectVideoTab: =>
    # This line is commented because we don't want to play the video when
    # the popup would be closed/hidden before selecting the video tab
    ## @showVideoPlayer()
    @assignCurrentTab @$videoTab
    $(document).trigger 'ga:event:video:clicked'

    if Sofatutor.videoPageForVisitors
      @showPopup('video')
    else if $("#without-lead-seo-video")[0] && Sofatutor.isSeoRequest && @$freeTrialPopup.is(':hidden')
      @playSeoVideo()
      if Sofatutor.isSeoRequest && @$exercisesApp.children().length > 0
        if Sofatutor.player.videoJsLoaded()
          @$videojsPlayer?.pause()
        else
          @$nativePlayer.pause()
    else
      if Sofatutor.player.videoJsLoaded()
        @$videojsPlayer.off() # Remove all event handler in direct traffic to not trigger 'video started' tracking.
        @$videojsPlayer.pause()
      else
        $('#player-0').off() # Remove all event handler in direct traffic to not trigger 'video started' tracking.
        @$nativePlayer.pause()
      @handleTabVideoSelected()

  selectExerciseTab: =>
    @assignCurrentTab @$exercisesTab
    @handleTabTestSelected()

  selectWorksheetTab: =>
    @assignCurrentTab @$workSheetTab
    window.open(@$workSheetTab.attr('href')) if Sofatutor.isSeoRequest && @$workSheetTab.attr('href')
    @showPopup('worksheet')
    $(document).trigger 'ga:event:worksheet:clicked'

  selectChatTab: =>
    @assignCurrentTab @$chatTab
    @showPopup('chat')

  selectLockAccordionButton: =>
    @selectExerciseTab()
    setTimeout =>
      new Sofatutor.Classes.ScrollHelper().animateScroll(@$videoDiv, 800, if @deviceSize.isPhone() then -100 else -400)
    , 500

  playSeoVideo: =>
    if Sofatutor.player.videoJsLoaded()
      @$videojsPlayer?.play()
      setTimeout =>
        @$videojsPlayer?.on('pause', @handlePauseForSeo)
      , 200
    else
      @$nativePlayer?.play()
      setTimeout =>
        @$nativePlayer?.addEventListener('pause', @handlePauseForSeo)
      , 200

  handlePauseForSeo: =>
    unless @$exercisesTab.hasClass 'is-current'

      if Sofatutor.player.videoJsLoaded()
        @$videojsPlayer.pause()
      else
        @$nativePlayer.pause()
      @showPopup('video')

  scrollToVideoPlayer: ->
    $('html, body').animate({
      scrollTop: $('.videos-title').offset().top - parseInt($('.videos-conversion-buttons-container').css('padding-top'), 10)
    }, 500)

  resetPopupContext: ->
    @context = null
    @closePopup()

  showVideoPlayer: =>
    @$videoPlaceholder.remove()
    @$videoPlayerContainer.removeClass('is-hidden')

  showPopup: (context) ->
    @context = context
    @closePopup()
    @switchWordingForContext()
    @showOverlay()
    @closeablePopupFlow()

    if Sofatutor.browser.isMobile
      @$freeTrialPopup.show()
    else
      @$freeTrialPopup.delay(400).fadeIn(400)

    $('.videos-stage').addClass('locked').css('height', @$freeTrialPopup.height() + 32) if $('.videos-stage').length
    $('.videos-stage--split').addClass('locked grid-container') if $('.videos-stage--split').length
    $('.seo-video-layout__video').addClass('opened') if $('.seo-video-layout__video').length

  closePopup: ->
    $('.trial-flow').removeClass 'shows-popup shows-popup--test shows-popup--video shows-popup--worksheet shows-popup--chat'
    @$freeTrialPopup?.hide()

  switchWordingForContext: ->
    $('.trial-flow [data-video-wording][data-test-wording]').each (index, element) =>
      if $(element).is('input')
        $(element).val($(element).data "#{@context}-wording")
      else
        $(element).html($(element).data "#{@context}-wording")

  showOverlay: ->
    $('.trial-flow').addClass "shows-popup shows-popup--#{@context}"

  closeablePopupFlow: ->
    $('.trial-flow-popup__button-close').on 'click', => @closePopup()

  handleTabVideoSelected: ->
    @showPopup('video') if Sofatutor.isSeoRequest && $(".js-trial-flow-popup").is(':visible')

    if Sofatutor.isSeoRequest
      @showPopup('video')
    else
      if ['chat', 'worksheet'].includes(@context)
        @resetPopupContext()

  handleTabVideoSeoPlayerPaused: ->
    if Sofatutor.isSeoRequest
      @showPopup('video')

  handleTabTestSelected: ->
    if Sofatutor.blockVideoPreviewOrganicTraffic
      @showPopup('test')
      return
    if Sofatutor.isSeoRequest
      if $(".js-trial-flow-popup").is(':visible')
        @showPopup('test')
        return
      # Exercise
      $(document).on 'exercises::waitForLead', =>
        @showPopup('test')
      # Old question
      $(document).on 'click', '.shared-feature-question .submit-question', =>
        @showPopup('test')

      if ['chat', 'worksheet'].includes(@context)
        @resetPopupContext()
    else
      @showPopup('test')

  videoBlockOrganicTraffic: ->
    if Sofatutor.isSeoRequest
      @showPopup('video')
