import React, { useState, useEffect } from 'react';
import './user_notifications/user_notifications.sass';
import './translations/componentsInit';
import { useTranslation } from 'react-i18next';
import makeRequest from '../lib/fetchService';
import UserNotificationsItem from './user_notifications/UserNotificationsItem';
import UserNotificationsIcon from './user_notifications/UserNotificationsIcon';

const UserNotifications = ({ userNotifications, accountProfilePath }) => {
  const { t } = useTranslation();
  const hasUnseenNotifications = userNotifications.some(
    userNotification => userNotification.notification.state === 'unseen'
  );
  const [showNotifications, setShowNotifications] = useState(
    hasUnseenNotifications
  );

  const notificationsPresent = userNotifications.length > 0;

  const toggleUserNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const linkToPath = path => {
    window.Turbolinks.visit(path);
  };

  const updateNotification = (id, state) => {
    const notificationUrl = `/user_notifications/${id}`;
    const data = { state: state };

    return makeRequest(notificationUrl, 'PATCH', data);
  };

  useEffect(() => {
    if (notificationsPresent) {
      userNotifications.forEach(userNotification => {
        const state =
          userNotification.notification.notification_key ===
          'school_year_upgrade_done'
            ? 'solved'
            : 'unsolved';
        updateNotification(userNotification.notification.id, state);
      });
    }
  }, []);

  return (
    <div>
      <UserNotificationsIcon
        onClick={toggleUserNotifications}
        showRedNotificationBubble={notificationsPresent}
      />
      {showNotifications && (
        <div
          className={`user-notifications-container ${notificationsPresent &&
            'with-notifications'}`}
        >
          {notificationsPresent ? (
            userNotifications.map(userNotification => (
              <UserNotificationsItem
                onClick={() => linkToPath(userNotification.link)}
                key={userNotification.notification.id}
                text={t(`user_notifications.${userNotification.notification.notification_key}`, {
                  additional_info: userNotification.additional_info
                })}
              />
            ))
          ) : (
            <UserNotificationsItem key={0} text={t('user_notifications.no_notifications')} />
          )}
        </div>
      )}
    </div>
  );
};

export default UserNotifications;
