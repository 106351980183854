export function initAnimateProgressBar() {
  const animateProgressBar = {
    init() {
      this.cacheDom();
      this.bindEvents();
    },
    cacheDom() {
      this.progressBarContainers = document.querySelectorAll(
        '.learning-success-progress-bar .progress-bar-container'
      );
      this.progressCircleItems = document.querySelectorAll(
        '.learning-success-progress-circle .learning-success-progress-circle__item'
      );
    },
    bindEvents() {
      window.addEventListener('scroll', this.animate.bind(this));
    },
    isVisible(e) {
      const rect = e.getBoundingClientRect();
      return rect.top <= window.innerHeight && rect.bottom >= 0;
    },
    animate() {
      this.progressBarContainers.forEach(e => {
        if (!this.isVisible(e) || e.classList.contains('is-progressed')) return;
        e.classList.add('is-progressed');

        setTimeout(() => {
          const progressBarFill = e.querySelector('.progress-bar-fill');
          const percent = e.querySelector('b').textContent;
          progressBarFill.style.width = percent + '%';
        }, 100);
      });

      this.progressCircleItems.forEach(e => {
        if (!this.isVisible(e) || e.classList.contains('is-progressed')) return;
        const circleFront = e.querySelector('.circle--front');
        const percent = parseInt(e.querySelector('b').textContent);
        const radius = circleFront.getAttribute('r');
        const circumference = 2 * Math.PI * radius;
        circleFront.style.strokeDasharray = circumference;
        circleFront.style.strokeDashoffset =
          ((100 - percent) / 100) * circumference;
        e.classList.add('is-progressed');
      });
    }
  };
  animateProgressBar.init();
}
