/* eslint-disable no-undef */

document.addEventListener('turbolinks:load', function() {
  let lastScrollTop = 0;
  const navbar = document.querySelector('.page-header--white');
  const registryPaymentProgressBarv1 = document.querySelector(
    '.js-registry-payment-progress-bar-v1'
  );
  const startScroll = 61;

  const navBarScroll = () => {
    let currentScroll = window.scrollY || document.documentElement.scrollTop;
    if (currentScroll > startScroll && currentScroll > lastScrollTop) {
      navbar.style.top = '-61px';
      if (registryPaymentProgressBarv1) {
        registryPaymentProgressBarv1.style.top = '0';
      }
    } else {
      navbar.style.top = '0';
      if (registryPaymentProgressBarv1) {
        registryPaymentProgressBarv1.style.top = '61px';
      }
    }
    lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
  };

  if (navbar) {
    window.addEventListener('scroll', navBarScroll);
  }
});
