import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import useOutsideClick from './useOutsideClick';
import './Dropdown.sass';
import DropdownMenu from './DropdownMenu';
import ArrowDown from '@images/layouts/arrow_down.svg';

export interface DropdownItem {
  id: string;
  name: string;
  imageUrl?: string;
}

export interface DropdownProps {
  /**
   * The id of the Dropdown component.
   */
  id: string;
  /**
   * The title of the Dropdown component.
   */
  title?: string;
  /**
   * The data for the Dropdown component.
   */
  data: DropdownItem[];
  /**
   * The position of the Dropdown component.
   */
  position?: 'bottom-left';
  /**
   * Determines if the Dropdown component has an image.
   */
  hasImage?: boolean;
  /**
   * The style of the Dropdown component.
   */
  style?: string;
  /**
   * The id of the selected item in the Dropdown component.
   */
  selectedId?: string;
  /**
   * The callback function called when an item is selected in the Dropdown component.
   * @param id - The id of the selected item.
   */
  onSelectCallback?: (id: string) => void;
  /**
   * The data attributes for the Dropdown component.
   */
  dataAttributes?: { [key: string]: string };
}

const Dropdown = ({
  id,
  title = 'Select',
  data,
  position = 'bottom-left',
  hasImage,
  style,
  selectedId,
  onSelectCallback,
  dataAttributes
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<DropdownItem | undefined>(
    selectedId ? data?.find(item => item.id === selectedId) : undefined
  );

  const handleChange = (item: DropdownItem) => {
    setSelectedItem(item);
    onSelectCallback && onSelectCallback(item.id);
    setIsOpen(false);
  };

  useEffect(() => {
    if (selectedId && data) {
      const newSelectedItem = data.find(item => item.id === selectedId);
      newSelectedItem && setSelectedItem(newSelectedItem);
    } else {
      setSelectedItem(undefined);
    }
  }, [selectedId, data]);

  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick({
    ref: dropdownRef,
    handler: () => setIsOpen(false)
  });

  const dropdownClass = classNames('dropdown-menu', {
    'position-bottom-left': position === 'bottom-left'
  });

  return (
    <div ref={dropdownRef} className="dropdown-container">
      <button
        id={id}
        aria-label="Toggle dropdown"
        aria-haspopup="true"
        aria-expanded={isOpen}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={classNames('dropdown-button', style)}
      >
        <span>{selectedItem?.name || title}</span>
        <img
          className={`dropdown-button__img${isOpen ? '--open' : ''}`}
          alt="arrow"
          src={ArrowDown}
        />
      </button>
      {isOpen && (
        <DropdownMenu
          dropdownClass={dropdownClass}
          id={id}
          data={data}
          selectedItem={selectedItem}
          hasImage={hasImage}
          handleChange={handleChange}
          dataAttributes={dataAttributes}
        />
      )}
    </div>
  );
};

export default Dropdown;
