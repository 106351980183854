import classNames from 'classnames';
import React from 'react';
import './Dropdown.sass';
import { DropdownItem } from './Dropdown';

/**
 * Props for the DropdownMenu component.
 *
 * @typedef {Object} DropdownMenuProps
 * @property {string} dropdownClass - The class name for the dropdown.
 * @property {string} id - The unique identifier for the dropdown.
 * @property {DropdownItem[] | undefined} data - The array of dropdown items.
 * @property {{ id: string } | null} selectedItem - The selected item in the dropdown.
 * @property {boolean} hasImage - Indicates whether the dropdown items have images.
 * @property {(item: { id: string; imageUrl?: string; name: string }) => void} handleChange - The callback function triggered when a dropdown item is selected.
 * @property {{ [key: string]: string } | undefined} dataAttributes - Additional data attributes for the dropdown.
 */
type DropdownMenuProps = {
  dropdownClass: string;
  id: string;
  data: DropdownItem[] | undefined;
  selectedItem: { id: string } | null;
  hasImage: boolean;
  handleChange: (item: { id: string; imageUrl?: string; name: string }) => void;
  dataAttributes?: { [key: string]: string };
};

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  dropdownClass,
  id,
  data,
  selectedItem,
  hasImage,
  handleChange,
  dataAttributes
}) => {
  return (
    <div aria-label="Dropdown menu" className={dropdownClass}>
      <ul
        role="menu"
        aria-labelledby={id}
        aria-orientation="vertical"
        className="dropdown-list"
      >
        {data?.map(item => (
          <li
            key={item.id}
            data-countrycode={item.id}
            onClick={e => {
              e.stopPropagation();
              handleChange(item);
            }}
            {...dataAttributes}
            className={classNames('dropdown-item', {
              'selected-item': selectedItem?.id === item.id
            })}
          >
            {hasImage && (
              <img
                src={item.imageUrl}
                alt="image"
                loading="lazy"
                className="dropdown-image"
              />
            )}
            {item.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

DropdownMenu.displayName = 'DropdownMenu';

export default DropdownMenu;
