import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { french: Boolean, gender: String };
  static targets = [
    'select',
    'baseVocabularyWord',
    'masculine',
    'masculine_plural',
    'feminine',
    'feminine_plural',
    'participle',
    'auxiliaryVerb',
    'genderRadioButtons'
  ];

  connect() {
    this.toggleType();
    if (this.selectTarget.value == 'noun') {
      this.handleVariationsFieldsForNoun();
    }
  }

  toggleType() {
    if (!this.englishValue) {
      let value = this.selectTarget.value;
      this.handleVerbFields(value);
      const isAdjective = value === 'adjective';
      const isNoun = value === 'noun';
      const hideVariationsForm = !isAdjective && !isNoun;
      // toggle base vocabulary field
      this.baseVocabularyWordTarget.hidden = !hideVariationsForm;
      // toggle adjective & noun labels
      this.masculineTarget.children[0].hidden = !isAdjective;
      this.masculineTarget.children[1].hidden = !isNoun;
      this.masculine_pluralTarget.children[0].hidden = !isAdjective;
      this.masculine_pluralTarget.children[1].hidden = !isNoun;
      this.feminineTarget.children[0].hidden = !isAdjective;
      this.feminineTarget.children[1].hidden = !isNoun;
      this.feminine_pluralTarget.children[0].hidden = !isAdjective;
      this.feminine_pluralTarget.children[1].hidden = !isNoun;
      // toggle variations fields
      if (isNoun) {
        this.handleVariationsFieldsForNoun();
      } else {
        this.masculineTarget.hidden = hideVariationsForm;
        this.masculine_pluralTarget.hidden = hideVariationsForm;
        this.feminineTarget.hidden = hideVariationsForm;
        this.feminine_pluralTarget.hidden = hideVariationsForm;
      }
      // toggle gender radio buttons
      this.genderRadioButtonsTarget.hidden = !isNoun;
    }
  }

  toggleGender(e) {
    if (e) {
      this.genderValue = e.target.value;
    }
    this.handleVariationsFieldsForNoun();
  }

  handleVariationsFieldsForNoun() {
    const showMasculineFields = this.genderValue === 'masculine';
    // toggle variations fields accordingly to gender
    this.masculineTarget.hidden = !showMasculineFields;
    this.masculine_pluralTarget.hidden = !showMasculineFields;
    this.feminineTarget.hidden = showMasculineFields;
    this.feminine_pluralTarget.hidden = showMasculineFields;
    // edit gender radio button on initilization
    this.genderRadioButtonsTarget.children[0].checked = showMasculineFields;
    this.genderRadioButtonsTarget.children[2].checked = !showMasculineFields;
  }

  handleVerbFields(value) {
    if (this.hasParticipleTarget)
      this.participleTarget.hidden = value !== 'verb';
    if (this.hasAuxiliaryVerbTarget)
      this.auxiliaryVerbTarget.hidden = value !== 'verb';
  }

  onSubmit() {
    let value = this.selectTarget.value;
    const isAdjective = value === 'adjective';
    const isNoun = value === 'noun';
    const hideVariationsForm = !isAdjective && !isNoun;
    // clear variations fields value
    if (hideVariationsForm || (isNoun && this.genderValue !== 'masculine')) {
      this.masculineTarget.children[2].value = '';
      this.masculine_pluralTarget.children[2].value = '';
    }

    if (hideVariationsForm || (isNoun && this.genderValue !== 'feminine')) {
      this.feminineTarget.children[2].value = '';
      this.feminine_pluralTarget.children[2].value = '';
    }

    // clear verb fields
    if (value !== 'verb') {
      this.participleTarget.children[1].value = '';
      this.auxiliaryVerbTarget.children[1].value = '';
    }
  }
}
