class Sofatutor.Classes.ContinueLearningPopup
  defaults:
    selectors:
      continueLearningPopupId   : '#continue-learning-popup'
      chooseProfilePopupId      : '#choose-profile-popup'
      continueLearningButton    : '.js-continue-learning-button'
      profileSwitchButton       : '.js-profile-switch-button'

  constructor: ->
    @$document                = $(document)
    @$continueLearningPopup   = $(@defaults.selectors.continueLearningPopupId)
    @$chooseProfilePopup      = $(@defaults.selectors.chooseProfilePopupId)
    @$continueLearningButton  = $(@defaults.selectors.continueLearningButton)
    @$profileSwitchButton     = $(@defaults.selectors.profileSwitchButton)
    @amplitudeEventProperties = @$continueLearningPopup.data('event-properties') || @$chooseProfilePopup.data('event-properties')

    @attachEventListeners()

    if @$continueLearningPopup[0]
      @openContinueLearningPopup()
    else if @$chooseProfilePopup[0] && !@$chooseProfilePopup.hasClass('hidden')
      @openChooseProfilePopup()

    @setMobileAppRedirectPath()

  attachEventListeners: ->
    @$document.on 'showChooseProfilePopup', () => @openChooseProfilePopup()
    @$continueLearningButton.on 'click', @continueLearning
    @$profileSwitchButton.on 'click', @trackProfileSwitch

  openContinueLearningPopup: ->
    @$continueLearningPopup.foundation()
    @$continueLearningPopup.foundation('open')

  openChooseProfilePopup: ->
    @$chooseProfilePopup.foundation()
    @$chooseProfilePopup.foundation('open')

  close: =>
    @$continueLearningPopup.foundation('close')

  refreshCookieValue : ->
    return unless Sofatutor.consentGivenForCategory('necessary')

    cookieExpirationMinutes = 30
    cookieExpirationDate = new Date(Date.now() + cookieExpirationMinutes * 60000)
    cookieProps = {
      profile_id: Sofatutor.profile.currentProfileId(),
      profile_type: Sofatutor.profile.currentProfileType(),
      expires: cookieExpirationDate
    }

    $.cookie('_sofatutor_active_profile', JSON.stringify(cookieProps), path: '/')

  continueLearning: =>
    @refreshCookieValue()
    @close()

    Sofatutor.tracking.amplitude.track('continue_learning_continue_clicked', @amplitudeEventProperties)
    if Sofatutor.profile.currentProfileType() == 'student' && Sofatutor.features.kidsNPSSurvey?
      Sofatutor.features.kidsNPSSurvey.open()

  trackProfileSwitch: =>
    Sofatutor.tracking.amplitude.track('continue_learning_switch_profile_clicked', @amplitudeEventProperties)

  setMobileAppRedirectPath: ->
    # Temporary fix: needed to redirect to the page where the profile popup appeared in the mobile app.
    if Sofatutor.browser.isMobileApp
      Sofatutor.MobileApp.profileRedirectPath = window.location.href
