import PropTypes from 'prop-types';
import React from 'react';

const UserNotificationsItem = ({ text, onClick }) => {
  return (
    <div className="user-notification" onClick={onClick}>
      <span
        className="user-notification__text"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
};

UserNotificationsItem.propTypes = {
  text: PropTypes.string.isRequired
};

export default UserNotificationsItem;
