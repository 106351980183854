$(document).on 'turbolinks:load', ->
  return unless $('.js-page-banner')[0]

  secondsToTimeRange = (timeRangeInSeconds) ->
    dayInSeconds = 86400
    hourInSeconds = 3600
    minuteInSeconds = 60

    days = Math.floor(timeRangeInSeconds / dayInSeconds)
    hours = Math.floor((timeRangeInSeconds - days * dayInSeconds) / hourInSeconds)
    minutes = Math.floor((timeRangeInSeconds - days * dayInSeconds - hours * hourInSeconds) / minuteInSeconds)
    seconds = Math.floor(
      timeRangeInSeconds -
        days * dayInSeconds -
        hours * hourInSeconds -
        minutes * minuteInSeconds
    )

    if (days < 10)
      days = '0' + days
    if (hours < 10)
      hours = '0' + hours
    if (minutes < 10)
      minutes = '0' + minutes
    if (seconds < 10)
      seconds = '0' + seconds

    days: days
    hours: hours
    minutes: minutes
    seconds: seconds

  $(document).on 'initializeBannerCountdown', ->
    if $('.js-banner-countdown')[0]
      expiresAt       = $('.js-banner-countdown').data('countdown-expires-at')
      parsedExpiresAt = Date.parse(expiresAt)

      expiresAtTimerInterval =
        setInterval ->
          date       = new Date()
          parsedDate = Date.parse(date)
          if (parsedDate <= parsedExpiresAt)
            timeRangeInSeconds = (parsedExpiresAt - parsedDate) / 1000

            # hide the countdown if it displays 100 days or more
            if (timeRangeInSeconds >= (100 * 86400))
              $('.js-banner-init-countdown').removeClass('has-countdown')
            else
              $('.js-banner-countdown').show()
              $('.js-banner-init-countdown').addClass('has-countdown')

              timeRange = secondsToTimeRange(timeRangeInSeconds)

              $('.js-banner-countdown-days').text(timeRange.days)
              $('.js-banner-countdown-hours').text(timeRange.hours)
              $('.js-banner-countdown-minutes').text(timeRange.minutes)
              $('.js-banner-countdown-seconds').text(timeRange.seconds)
          else

            $('.js-banner-init-countdown').hide()

            clearInterval(expiresAtTimerInterval)
        , 1000

      true

  $(document).trigger 'initializeBannerCountdown'
