class Sofatutor.Classes.StudentFeedbackPopup
  defaults:
    selectors:
      popUpId       : '#student-feedback-popup'
      answerOptions : '.student-feedback-popup__options-item'
      nextButtons   : '.student-feedback-popup__next-button'
      cancel        : '.cancel-survey'
      finalStepImg  : '.student-feedback-popup__final-step-image'

  constructor: ->
    @$popUp            = $(@defaults.selectors.popUpId)
    @$answerOptions    = $(@defaults.selectors.answerOptions)
    @$nextButtons      = $(@defaults.selectors.nextButtons)
    @$cancel           = $(@defaults.selectors.cancel)
    @$finalStepImg     = $(@defaults.selectors.finalStepImg)
    @currentStep       = 1

    @initPopUp()
    @attachEventListeners()

  initPopUp: ->
    @$popUp.foundation()

  attachEventListeners: ->
    @$answerOptions.on 'click', @nextStep
    @$nextButtons.on 'click', @nextStep
    @$cancel.on 'click', @cancelSurvey

  open: =>
    @updateStudentFeedbackSurveySeen()
    @$popUp.foundation('open')

  updateStudentFeedbackSurveySeen: ->
    notifyPath = @$popUp.data('notify-path')
    $.ajax
      type: 'PATCH',
      url:  notifyPath

  nextStep: (event) =>
    if @currentStep == 3
      @trackingStepName = $('#student_feedback_field').data('step-name')
      @trackingValue = $('#student_feedback_field').val()
    else
      @trackingValue = $(event.currentTarget).data('answer')
      @trackingStepName = $(event.currentTarget).data('step-name')

    @trackSurveyResponse()

    $("#step-#{@currentStep}").hide()
    @currentStep++
    $("#step-#{@currentStep}").show()

    if @currentStep == 4
      @$finalStepImg.addClass('animate__animated animate__faster animate__flip')
      setTimeout =>
        @$popUp.foundation('close')
      , 3000

  trackSurveyResponse: =>
    $.ajax
      type: 'POST',
      url:  @$popUp.data('survey-response-path'),
      data: { survey_type: "learning_group_students_feedback_#{@trackingStepName}", value: @trackingValue }

  cancelSurvey: =>
    @$popUp.foundation('close')
