import React from 'react';

export const chatbotSettings = (t, closeChatIcon, sofabuddyThreadId, storageKey) => {
  return ({
    header: {
      showAvatar: false,
      title: t('sofabuddy_chat_bot.title'),
      closeChatIcon: closeChatIcon
    },
    chatWindow: {
      showMessagePrompt: false
    },
    advance: {
      useAdvancedMessages: true,
      useAdvancedStyles: true
    },
    tooltip: {
      mode: 'NEVER'
    },
    notification: {
      disabled: true
    },
    general: {
      embedded: false,
      flowStartTrigger: 'ON_CHATBOT_INTERACT',
      fontFamily: 'Open Sans, Helvetica, Arial, sans-serif'
    },
    chatHistory: {
      storageKey: storageKey,
      viewChatHistoryButtonText: (
        <>
          {t('sofabuddy_chat_bot.show_chat_history')} <i className="icon icon--replay"></i>
        </>
      ),
      autoLoad: sofabuddyThreadId ? true : false
    },
    botBubble: {
      dangerouslySetInnerHtml: true,
      simStream: window.Sofatutor.env == 'test' ? false : true
    },
    chatInput: {
      enabledPlaceholderText: t('sofabuddy_chat_bot.placeholder_text')
    },
    footer: {
      text: t('sofabuddy_chat_bot.mistake_hint')
    },
    fileAttachment: {
      disabled: true
    },
    emoji: {
      disabled: true
    }
  });
};