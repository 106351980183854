/* eslint-disable no-undef */
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['selectedCountry'];

  updateCountry(event) {
    this.selectedCountryTarget.innerHTML = event.target.dataset.countrycode;

    if (window.Sofatutor && window.Sofatutor.MobileApp) {
      const appEvent = window.Sofatutor.MobileApp.createEvent(
        'selectedCountry',
        {
          countryCode: event.target.dataset.countrycode
        }
      );

      if (typeof window.Sofatutor.MobileApp.sendEvent === 'function') {
        window.Sofatutor.MobileApp.sendEvent(appEvent);
      } else {
        // eslint-disable-next-line no-console
        console.error('window.Sofatutor.MobileApp.sendEvent is not defined');
      }
    }
  }
}
