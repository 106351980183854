import React from 'react';
import '../translations/componentsInit';
import './styles.sass';
import { useTranslation } from 'react-i18next';
import introChatIcon from './images/sofabuddy_chatbot.svg';

const IntroMessage = ({ startChat }) => {
  const { t } = useTranslation();

  return (
    <div className="intro-message-container">
      <div className="intro-message-container__initial-introduction card grid-x text-center">
        <img src={introChatIcon} alt={t('sofabuddy_chat_bot.introduction.sofabuddy_icon_alt_text')} />
        <h3>{t('sofabuddy_chat_bot.introduction.title')}</h3>
        <h5>{t('sofabuddy_chat_bot.introduction.description')}</h5>
        <button
          onClick={startChat}
          className="button button-medium"
          style={{
            width: '178px',
            height: '40px',
            margin: '0 auto 1rem',
          }}
        >
          {t('sofabuddy_chat_bot.introduction.start_chat_button')}
        </button>
        <a href="#" className="learn-more" style={{ display: 'none' }}>
          {t('sofabuddy_chat_bot.introduction.learn_more')}
        </a>
      </div>
      <div className="intro-message-container__warnings card space-top-24">
        <div className="grid-x space-left-16 space-top-24">
          <i className="icon icon--info"></i>
          <h5>{t('sofabuddy_chat_bot.introduction.warnings.fact_check.title')}</h5>
          <p>{t('sofabuddy_chat_bot.introduction.warnings.fact_check.message')}</p>
        </div>
        <div className="grid-x space-left-16 space-top-24">
        <i className="icon icon--books"></i>
          <h5>{t('sofabuddy_chat_bot.introduction.warnings.stay_updated.title')}</h5>
          <p>{t('sofabuddy_chat_bot.introduction.warnings.stay_updated.message')}</p>
        </div>
        <div className="grid-x space-left-16 space-top-24">
        <i className="icon icon--heart primary"></i>
          <h5>{t('sofabuddy_chat_bot.introduction.warnings.feedback.title')}</h5>
          <p style={{ marginBottom: '12px'}}>{t('sofabuddy_chat_bot.introduction.warnings.feedback.message')}</p>
        </div>
      </div>
    </div>
  );
};

export default IntroMessage;
