import React from 'react';

const ErorrMessage = message => {
  return (
    <div className="error-message">
      <i className="error-message__icon icon icon--circle-close red"></i>
      <p>{message}</p>
    </div>
  );
};

export default ErorrMessage;
