const schoolDirectoryExpiredLicensePopup = () => {
  new Foundation.Reveal($('#school-directory-expired-license-popup'));

  const togglePopupContentButton = document.querySelectorAll(
    '.js-toggle-popup-content'
  );

  const togglePopupContent = () => {
    document
      .querySelector('.js-terms-and-conditions-screen')
      .classList.toggle('is-hidden'),
      document.querySelector('.js-start-screen').classList.toggle('is-hidden');
  };

  const popupSeenButton = document.querySelector('.js-popup-seen-button');
  const acceptTermsButton = document.querySelector('.js-accept-terms-button');
  const openRedeemVoucherCodeButton = document.querySelector(
    '.js-popup-go-to-redeem-code'
  );
  const goBackButton = document.querySelector('.js-popup-go-back');

  const updateExpiredLicensePopupSeen = () => {
    const requestPath = popupSeenButton.getAttribute('data-request-path');
    $.ajax({
      type: 'PATCH',
      url: requestPath
    });
  };

  const confirmTermsAndConditions = () => {
    if (document.getElementById('accept_terms_and_conditions').checked) {
      const requestPath = acceptTermsButton.getAttribute('data-request-path');
      $.ajax({
        type: 'PATCH',
        url: requestPath,
        data: { accepted_terms_and_conditions: true }
      });
    }
  };

  const switchStartScreenAndRedeemVoucherCodeScreen = () => {
    document.querySelector('.js-start-screen').classList.toggle('is-hidden'),
      document
        .querySelector('.js-redeem-voucher-screen')
        .classList.toggle('is-hidden');
  };

  const attachEventListeners = () => {
    togglePopupContentButton?.forEach(button =>
      button.addEventListener('click', togglePopupContent)
    );
    openRedeemVoucherCodeButton?.addEventListener('click', () =>
      switchStartScreenAndRedeemVoucherCodeScreen()
    );
    goBackButton?.addEventListener('click', () =>
      switchStartScreenAndRedeemVoucherCodeScreen()
    );
    popupSeenButton?.addEventListener('click', () =>
      updateExpiredLicensePopupSeen()
    );
    document
      .querySelector('.form-checkbox__input')
      ?.addEventListener('change', e => {
        document.querySelector('.js-accept-terms-button').disabled = !e
          .currentTarget.checked;
      });
    document
      .querySelector('.js-accept-terms-button')
      ?.addEventListener('click', () => {
        confirmTermsAndConditions();
      });
  };
  attachEventListeners();
};

$(document).on('turbolinks:before-visit', () => {
  Turbolinks.clearCache();
});

$(document).on('ready', () => {
  if (
    document.querySelector('#school-directory-expired-license-popup')
  ) {
    schoolDirectoryExpiredLicensePopup();
    $('#school-directory-expired-license-popup').foundation('open');
  }
});
