class Sofatutor.Classes.HomeworkChat
  defaults:
    selectors:
      chat: '.js-homework-chat'
      trigger: '.js-chat-trigger'
      showCustomizationPopup: '.js-show-customization-popup'
      allowFunctionalCookies: '.js-allow-functional-cookies'
      flyout: '.js-subject-flyout'
      cookieFlyout: '.js-chat-cookie-flyout'
      closeFlyoutIcon: '.js-close-flyout'
      subjectLink: '.js-subject-link'

  constructor: ->
    @$element                      = $(@defaults.selectors.chat)
    @$chatTrigger                  = $(@defaults.selectors.trigger, @$element)
    @$chatFlyout                   = $(@defaults.selectors.flyout, @$element)
    @$cookieFlyout                 = $(@defaults.selectors.cookieFlyout, @$element)
    @$closeFlyoutIcon              = $(@defaults.selectors.closeFlyoutIcon, @$element)
    @$subjectLink                  = $(@defaults.selectors.subjectLink, @$element)
    @$showCustomizationPopup       = $(@defaults.selectors.showCustomizationPopup)
    @$allowFunctionalCookiesButton = $(@defaults.selectors.allowFunctionalCookies)
    @$lastSubjectItem              = @$subjectLink.parent().last()
    @deviceSize                    = new Sofatutor.Classes.DeviceSizeHelper
    @trackingConsent               = new Sofatutor.Classes.TrackingConsent unless Sofatutor.consentGivenForCategory('functionality')
    @customizationPopup            = new Sofatutor.Classes.TrackingConsentCustomizationPopup unless Sofatutor.consentGivenForCategory('functionality')
    @bindEvents()
    olark 'api.box.hide'

  bindEvents: ->
    olark 'api.chat.onReady', ->
      @showChatTrigger()

    olark 'api.chat.onMessageToVisitor', =>
      @hideChatTrigger()

    @$chatTrigger.on 'click.openChat', => @openChat()

    @$closeFlyoutIcon.on 'click', =>
      @closeFlyout()
      setTimeout =>
        @showChatTrigger()
      , 400

    @$subjectLink.on 'click', (e) =>
      @closeFlyout()
      @initSubjectChat(e.currentTarget)

    @$showCustomizationPopup.on 'click', =>
      @customizationPopup.open()
      @$cookieFlyout.hide()
      @showChatTrigger()

    @$allowFunctionalCookiesButton.on 'click', =>
      @trackingConsent.permitCategoryFunctional()
      @$cookieFlyout.hide()
      @showChatTrigger()

  openChat: ->
    Sofatutor.tracking.amplitude.track('homeworkchat_clicked')
    @hideChatTrigger()

    if Sofatutor.consentGivenForCategory('functionality')
      if @$chatFlyout[0]
        @showSubjectFlyout()
      else
        @showOlarkWindow()
    else
      @showCookieHintFlyout()

  showChatTrigger: ->
    @$chatTrigger.fadeIn('fast')

  hideChatTrigger: ->
    @$chatTrigger.hide()

  showSubjectFlyout: ->
    @$element.addClass 'is-active'
    @$chatFlyout.slideDown 400, =>
      if @deviceSize.isPhone()
        @$element.animate
          top: 0
        , 500
      tileBottomPosition = @$lastSubjectItem.position().top + @$lastSubjectItem.height()
      if tileBottomPosition > $(window).outerHeight()
        @$lastSubjectItem.parent().css('overflow-y', 'scroll')
      else
        @$lastSubjectItem.parent().css('overflow-y', 'initial')

  showCookieHintFlyout: ->
    @$element.addClass 'is-active'
    @$cookieFlyout.show()

  closeFlyout: ->
    @$cookieFlyout.hide()
    @$chatFlyout.hide()
    @$element.removeClass 'is-active'
    if @deviceSize.isPhone()
      @$element.css('top', 'initial')

  initSubjectChat: (elem) ->
    $this     = $(elem)
    group     = $this.data('chat-group')
    subject   = $this.data('chat-subject')
    chatTitle = $this.data('chat-title')

    olark('api.chat.setOperatorGroup', { group: group })
    olark('api.chat.sendNotificationToOperator', { body: subject })

    @showOlarkWindow()
    $('.olark-top-bar-text').text(chatTitle)

  showOlarkWindow: ->
    olark 'api.box.expand'
    olark 'api.box.onShrink', =>
      olark 'api.box.hide'
      @showChatTrigger()
