import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from './componentsDe.json';

export default i18next.use(initReactI18next).init({
  initImmediate: false,
  preload: ['de'],
  fallbackLng: 'de',
  lng: 'de',
  resources: {
    de: {
      ...de
    }
  },

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ','
  }
});
