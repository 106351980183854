import { Loader } from '@googlemaps/js-api-loader';

class GooglePlacesSchoolSearch {
  constructor(model) {
    this.model = model;
    this.input = document.getElementById('js-google-search-field');
    this.$schoolInformationInputs = $(
      `#${this.model}_country, #${this.model}_federal_state, #${this.model}_city, #${this.model}_zip_code, #${this.model}_organization`
    );
    this.$googleApiIdField = $(`#${this.model}_google_api_id`);
    this.$zipCodeInputField = $(`#${this.model}_zip_code`);
    this.$cityInputField = $(`#${this.model}_city`);
    this.$federalStateInputField = $(`#${this.model}_federal_state`);
    this.$countryInputField = $(`#${this.model}_country`);
    this.$googleApiIdField = $(`#${this.model}_google_places_id`);

    this.options = {
      fields: ['address_components', 'name', 'place_id'],
      strictBounds: false,
      types: ['primary_school', 'school', 'secondary_school']
    };

    this.autocomplete;
    this.initGooglePlacesApi();
  }

  initGooglePlacesApi = () => {
    const loader = new Loader({
      apiKey: 'AIzaSyCglFdnxhlnRLUPmg4yCbAt6LNLCaeEcZc',
      version: 'weekly',
      libraries: ['places']
    });

    loader.load().then(() => {
      this.autocomplete = new google.maps.places.Autocomplete(
        this.input,
        this.options
      );
      this.bindEvents();
    });
  };

  bindEvents = () => {
    this.autocomplete.addListener('place_changed', () =>
      this.showAutoCompleteResults()
    );
  };

  showAutoCompleteResults = () => {
    this.$schoolInformationInputs.val('');
    this.$googleApiIdField.val('');

    const place = this.autocomplete.getPlace();
    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'postal_code':
        case 'plus_code':
          this.$zipCodeInputField.val(component.long_name);
          break;
        case 'locality':
        case 'postal_town':
          this.$cityInputField.val(component.long_name);
          break;
        case 'administrative_area_level_1':
          if (this.$cityInputField.val() === '') {
            this.$cityInputField.val(component.long_name);
          } else {
            if (
              $(`#${this.model}_federal_state`).find('option[value="' + component.short_name + '"]').length > 0
            ) {
              this.$federalStateInputField.val(component.short_name);
            } else {
              this.$federalStateInputField.val('other');
            }
          }
          break;
        case 'country':
          if (component.short_name !== 'DE') {
            this.$federalStateInputField.val('other');
          }
          if (
            $(`#${this.model}_country`).find(
              'option[value="' + component.short_name + '"]'
            ).length > 0
          ) {
            this.$countryInputField.val(component.short_name);
          } else {
            $(`select#${this.model}_country`)[0].add(
              new Option(component.long_name, component.short_name),
              void 0
            );
            this.$countryInputField.val(component.short_name);
          }
          break;
      }
    }
    $(`#${this.model}_google_places_id`).val(place.place_id);
    $(`#${this.model}_organization`).val(place.name);
    if (this.$zipCodeInputField.val() == '') {
      this.$zipCodeInputField.val('other');
    }
  };
}

export default GooglePlacesSchoolSearch;
