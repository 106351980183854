// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.
// If you have a controller inside a folder, you must include the folder name in the controller name followed by a --.
// For example, if you have a controller named controllers/mobile-app/welcome_screen_controller.js, you must include it your haml file as "mobile-app--welcome-screen":

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

const application = Application.start();
const context = require.context('controllers', true, /_controller\.js$/);

const contextComponents = require.context(
  '../../components',
  true,
  /_controller\.js$/
);
application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents)
  )
);
