import packageFile from '../vocabulary-trainer/package.json';

const headers = {
  'X-CSRF-TOKEN':
    document.querySelector('meta[name="csrf-token"]') &&
    document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  'X-Requested-With': 'XMLHttpRequest',
  'Vocabulary-Trainer-Version': packageFile['vocabulary-trainer-version']
};

export default function makeRequest(url, method, data) {
  let fetchOptions = {
    method: method || 'GET',
    headers: headers,
    credentials: 'same-origin'
  };

  return new Promise((resolve, reject) => {
    if (method !== 'GET' && data) {
      fetchOptions = { ...fetchOptions, body: JSON.stringify(data) };
    }
    fetch(url, fetchOptions)
      .then(res => {
        if (res.status >= 200 && res.status < 300) {
          return resolve(res);
        } else {
          if (res.status === 400) {
            res.json().then(res => {
              handleProfileCookieIsMissingError(res);
              handleNewRelease(res);
            });
          }
          res.json().then(response => {
            return reject(
              new Error(JSON.stringify(response?.errors), {
                cause: res.statusText
              })
            );
          });
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

const handleProfileCookieIsMissingError = res => {
  if (res.error === 'The profile cookie is missing') {
    window.Sofatutor?.profile?.showChooseProfilePopup();
  }
};

const handleNewRelease = res => {
  if (res.error === 'should reload') {
    window.Turbolinks.visit(window.location.toString(), {
      action: 'replace'
    });
  }
};
