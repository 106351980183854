class Sofatutor.Classes.AutomatedChatMessage
  constructor: ->
    @cache()

    if @$homeworkChatCookie != true && @$homeworkChatCookie < @currentDate.getTime() - 600000
      setTimeout =>
        @bindEvents()
        @slideOut()
      , if Sofatutor.env == 'test' then 0 else 10000
      lifeTime = 24 * 3600 * 1000 # 24 hrs
      @currentDate.setTime(@currentDate.getTime() + lifeTime)

      if Sofatutor.consentGivenForCategory('functionality')
        $.cookie '_sofatutor_homeworkchat_hint', true, { expires: @currentDate }

    if !@$homeworkChatCookie
      setTimeout =>
        @createChatCookie()
        @bindEvents()
        @slideOut()
      , if Sofatutor.env == 'test' then 0 else 10000

  cache: ->
    @$homeworkChat = $ '.js-homework-chat'
    @$chatTrigger = $ '.js-chat-trigger'
    @$slide = $ '.homework-chat__slide'
    @$icon = $ '.homework-chat__icon'
    @$bubbleText = $ '.homework-chat__bubble-text'
    @$homeworkChatCookie = $.cookie '_sofatutor_homeworkchat_hint'
    @$notification = $ '.homework-chat__notification'
    @currentDate = new Date
    @deviceSize = new Sofatutor.Classes.DeviceSizeHelper

  bindEvents: ->
    if @deviceSize.isPhone()
      @$chatTrigger.off 'click.openChat'
      @$chatTrigger.one 'click', (e) =>
        @$notification.hide()
        @$bubbleText.html @$bubbleText.data('automatedMessageBubble')
        @$icon.toggleClass 'icon--chat icon--edit'
        @$slide.animate { width: '256px'}, 250
        @$chatTrigger.on 'click.openChat', =>
          Sofatutor.features.homeworkChat.openChat()
          @$icon.removeClass 'icon--edit'
          @$icon.addClass 'icon--chat'
          @$slide.css 'width': '0'
          @$homeworkChat.css 'bottom': '16px'
    else
      @$chatTrigger.one "click", (e) =>
        @$slide.stop(true, true)
        @$icon.removeClass 'icon--edit'
        @$icon.addClass 'icon--chat'
        @$slide.css 'margin-bottom': '0'
        @$bubbleText.text @$bubbleText.data('chatBubble')

  createChatCookie: ->
    date = new Date
    cookieCreationDate = date.getTime()

    if Sofatutor.consentGivenForCategory('functionality')
      $.cookie '_sofatutor_homeworkchat_hint', cookieCreationDate

  slideOut: ->
    if @deviceSize.isPhone()
      @$notification.show()
      @$homeworkChat.animate { bottom: '32px'}, 250
      @$slide.css 'margin-bottom': '-11px'
    else
      @$slide.animate { width: 'toggle' }, 250, =>
        @$icon.toggleClass 'icon--chat icon--edit'
        @$slide.css 'margin-bottom': '-11px'
        @$bubbleText.html @$bubbleText.data('automatedMessageBubble')
        @$slide.animate { width: 'toggle' }, 250
