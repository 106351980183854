import PropTypes from 'prop-types';
import React from 'react';

const UserNotificationsIcon = ({ onClick, showRedNotificationBubble }) => {
  return (
    <button className="icon icon--notification" onClick={onClick}>
      {showRedNotificationBubble && <div className="new" />}
    </button>
  );
};

UserNotificationsIcon.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default UserNotificationsIcon;
