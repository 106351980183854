$(document).on('turbolinks:load', function() {
  if ($('.js-page-header-teacher-box')[0]) {
    $('.js-show-teacher-box-popup').on('click', function() {
      const subject = $(this).data('chat-subject');

      let $teacherBoxPopup = $('#teacher-box-question-popup');
      let $teacherBoxConfirmPopup = $('#teacher-box-confirm-popup');
      let teacherBoxReveal = new Foundation.Reveal($teacherBoxPopup);
      teacherBoxReveal.open();

      const setupForm = function () {
        $('form', $teacherBoxPopup).ajaxForm({
          dataType: 'script',
          success: function() {
            teacherBoxReveal.close();
            let teacherBoxConfirmReveal = new Foundation.Reveal($teacherBoxConfirmPopup);
            teacherBoxConfirmReveal.open();
          },
          error: function (response) {
            $('form', $teacherBoxPopup).html(response.responseText);
            setupForm();
          }
        });
      };

      setupForm();

      $('#teacher_box_question_subject').val(subject);
    });
  }
});
