HTMLElement.prototype.show = function() {
  this.style.display = 'block';
};

HTMLElement.prototype.hide = function() {
  if (this.classList.contains('has-tip')) return;

  this.style.display = 'none';
};

HTMLElement.prototype.toggle = function() {
  if (window.getComputedStyle(this).display === 'none') {
    this.style.display = 'block';
  } else {
    this.style.display = 'none';
  }
};

HTMLElement.prototype.removeClass = function(args) {
  this.classList.remove(args);
};

HTMLElement.prototype.addClass = function(args) {
  this.classList.add(args);
};

HTMLElement.prototype.toggleClass = function(args) {
  this.classList.toggle(args);
};

HTMLElement.prototype.attr = function(name, newValue) {
  newValue && this.setAttribute(name, newValue);
  return this.getAttribute(name);
};

HTMLElement.prototype.val = function() {
  return this.value;
};
