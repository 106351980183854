import GooglePlacesSchoolSearch from '../account_request/google_places_school_search';

class TeacherSchoolSelectionPopup {
  constructor() {
    this.$popup = $('#teacher-school-selection-popup');
    this.$closePopupButton = $('.js-close-teacher-school-selection-popup')
    this.$addSchoolManuallyLink = $('.teacher-school-selection-popup__add-school-link');
    this.$submitButton = $('.teacher-school-selection-popup__save-button');
    this.$schoolInformationInputs = $(
      '#teacher_account_request_country, #teacher_account_request_federal_state, #teacher_account_request_city, #teacher_account_request_zip_code, #teacher_account_request_organization'
    );
    this.$googleSearchField = $('#js-google-search-field');

    // eslint-disable-next-line no-undef
    if (Sofatutor.env != 'test') {
      new GooglePlacesSchoolSearch('teacher_account_request');
    }
    this.bindEvents();
    this.$popup.foundation();
    this.$popup.foundation('open');
    this.isValid = true;
  }

  bindEvents = () => {
    this.$closePopupButton.on('click', () => {
      $.ajax({
        type: 'PATCH',
        url: this.$popup.data('update-path'),
        success: this.$popup.foundation('close')
      });
    });

    this.$addSchoolManuallyLink.on('click', () => {
      this.toggleForm();
    });

    this.$schoolInformationInputs.on('change blur focusout keyup', () => {
      this.validateForm(this.$schoolInformationInputs);
      this.$submitButton.attr('disabled', !this.isValid);
    });

    this.$googleSearchField.on('change blur focusout keyup', () => {
      this.validateForm(this.$googleSearchField);
      this.$submitButton.attr('disabled', !this.isValid);
    });
  };

  toggleForm = () => {
    $('.teacher-school-selection-popup__google-search-container').toggleClass('is-hidden');
    $('.teacher-school-selection-popup__container').toggleClass('is-hidden');
    this.$googleSearchField.off();
    this.$submitButton.attr('disabled', false);
  };

  validateForm = selectors => {
    this.isValid = true;
    selectors.each((index, elem) => {
      if ($(elem).val() === '') {
        this.isValid = false;
      }
    });
    return this.isValid;
  };
}

$(function() {
  if ($('#teacher-school-selection-popup')[0]) {
    new TeacherSchoolSelectionPopup();
  }
});
