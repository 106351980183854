class Sofatutor.Classes.KidsNPSSurvey
  defaults:
    selectors:
      popUpId       : '#kids-nps-survey-popup'
      answerOptions : '.kids-nps-survey-popup__options-item'
      nextButtons   : '.kids-nps-survey-popup__next-button'
      cancel        : '.cancel-survey'
      finalStepImg  : '.kids-nps-survey-popup__final-step-image'

  constructor: ->
    @$popUp            = $(@defaults.selectors.popUpId)
    @$answerOptions    = $(@defaults.selectors.answerOptions)
    @$nextButtons      = $(@defaults.selectors.nextButtons)
    @$cancel           = $(@defaults.selectors.cancel)
    @$finalStepImg     = $(@defaults.selectors.finalStepImg)
    @currentStep       = 1
    @profileProperties = @$popUp.data('event-properties')

    @initPopUp()
    @attachEventListeners()

  $currentNextButton: =>
    $("#finish-step-#{@currentStep}")

  initPopUp: ->
    @$popUp.foundation()

  attachEventListeners: ->
    @$answerOptions.on 'click', @toggleSelection
    @$nextButtons.on 'click', @nextStep
    @$cancel.on 'click', @cancelSurvey

  open: =>
    @updateKidsNpsSurveySeen()
    Sofatutor.tracking.amplitude.track('kids_nps_survey_seen', @profileProperties )
    @$popUp.foundation('open')
    @playAudio()

  updateKidsNpsSurveySeen: ->
    notifyPath = @$popUp.data('notify-path')
    $.ajax
      type: 'PATCH',
      url:  notifyPath

  toggleSelection: (event) =>
    @$answerOptions.removeClass('is-active')
    $(event.currentTarget).addClass('is-active')
    @trackingValue = $(event.currentTarget).data('answer')
    $("#finish-step-#{@currentStep}").attr('disabled', false)

  playAudio: =>
    if @audio?
      @audio.pause()

    @audio = new Audio("/assets/nps_step_#{@currentStep}.mp3")
    @audio.play()

  nextStep: =>
    @trackStep()

    $("#step-#{@currentStep}").hide()
    @currentStep++
    $("#step-#{@currentStep}").show()

    if @currentStep == 6
      @$finalStepImg.addClass('animate__animated animate__faster animate__flip')

    @playAudio()

  trackStep: ->
    if @currentStep == 1
      @trackSurveyResponse()

    Sofatutor.tracking.amplitude.track(@$currentNextButton().data('event'), Object.assign(@profileProperties, kids_nps_rating: @trackingValue))

  trackSurveyResponse: =>
    $.ajax
      type: 'POST',
      url:  @$popUp.data('survey-response-path'),
      data: { survey_type: 'kids_nps_like_sofatutor', value: @trackingValue }

  finishSurvey: ->
    @$popUp.foundation('close')

  cancelSurvey: =>
    @audio.pause()

    if @currentStep != 6
      Sofatutor.tracking.amplitude.track('kids_nps_survey_canceled', Object.assign(@profileProperties, kids_nps_closed_on: @$currentNextButton().data('event')))

    @finishSurvey()
